import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NarrowContent from "../components/visualContainers/narrowContent"
import PageTitle from "../components/contentComponents/pageTitle"
import { H3, P } from "../components/contentComponents/text"
import styled from "styled-components"
import WideImage from "../components/contentComponents/wideImage"
import MaterialContainer from "../components/visualContainers/materialContainer"
import mainImage from "../images/about1.jpg"
import Wall from "../components/visualComponents/wall"
import GlowStick from "../components/visualComponents/glowstick"
import Button, { Buttons } from "../components/contentComponents/button"
import Disc from "../components/visualComponents/disc"
import Cross from "../components/visualComponents/cross"
import { useEffect, useState } from "react"

const AboutPage = (props) => {
  const t = props.pageContext.pageData
  const {site, locale, pageName, fullPath } = props.pageContext
  const [merchantOnboardingURL, setMerchantOnboardingURL] = useState("")

  useEffect(() => {
    setMerchantOnboardingURL("https://merchant." + window.location.host + "/onboarding")
  }, []);

  return (
    <Layout site={site} language={locale} pagePath={props.path} pageName={pageName}>
      <Seo title={t.pageTitle} lang={locale} pagePath={fullPath} site={site} meta={t.seo} />
      <NarrowContent>
        <PageTitle title={t.hero.title} />
        <TextUnderPageTitle strong={true} addMargins={true} mb={40}>
          <Wall id={"wall1"} hideMobile={true} height={30} position="top: 0; right: -40px;"/>
          <Wall id={"wall2"} hideMobile={true} height={30} position="top: 40px; right: -40px;"/>
          {t.hero.body}
        </TextUnderPageTitle>
      </NarrowContent>
      <WideImage image={mainImage}>
        <BubbleAnimator>
          <BubbleContainer className="center-vertical" material="peachGradient" circle={true} width={300} zIndex={6}>
            <P center={true} white={true} small={false}>{t.hero.bubbleBody}</P>
          </BubbleContainer>
        </BubbleAnimator>
        <Disc material="lightBlueOpaque" hideMobile={true} zIndex={6} width={80} position="left: -100px; top: 0;"/>
        <Disc material="lightBlueOpaque" hideMobile={true} zIndex={6} width={64} position="right: -32px; bottom: 95px;"/>
        <Wall id={"wall3"} hideMobile={true} height={30} position="bottom: 5px; left: -60px;"/>
        <Wall id={"wall4"} hideMobile={true} height={30} position="bottom: 5px; left: -40px;"/>
      </WideImage>
      <NarrowContent>
        <GlassTextPanel zIndex={7} mb={80} material="transparent" className="motion" dataYFrom={40} dataYTo={-40}>
          <H3 strong={true} white={true}>{t.hero.bodyBelowImage}</H3>
        </GlassTextPanel>
        <Buttons className="center" mt={80} mb={80} >
          <Button title={t.hero.button1Title} linkTo={site.pages.invoice.defaultPath}/>
          <Button title={t.hero.button2Title} linkTo={site.pages.slice.defaultPath}/>
        </Buttons>
        <MaterialContainer zIndex={1} material="walled">
          <H3 addMargins={true} mt={0}>{t.walledBox.boxParagraph1}</H3>
          <H3 addMargins={true} mb={0}>{t.walledBox.boxParagraph2}</H3>
          <Wall id={"wall5"} hideMobile={true} height={90} position="bottom: 0px; right: -110px;"/>
          <Wall id={"wall6"} hideMobile={true} height={64} position="bottom: -80px; left: -130px;"/>
          <Wall id={"wall7"} hideMobile={true} height={64} position="bottom: -80px; left: -100px;"/>
        </MaterialContainer>
        <Buttons className="center" mt={80} mb={0} >
          <Button external={true} title={t.walledBox.buttonTitle} linkTo={merchantOnboardingURL}/>
        </Buttons>
      </NarrowContent>

      <BlackDiscs>
        <MaterialContainer padding={38} minWidth={235} className="center motion" columns={4} circle={true} material="almostBlack" dataYFrom={40} dataYTo={-40}>
          <GlowStick1 width={80} height={3}/>
          <P white={true} center={true} middle={true}>{t.blackDiscs.disc1}</P>
        </MaterialContainer>
        <MaterialContainer padding={38} minWidth={235} className="center motion" columns={4} circle={true} material="almostBlack" dataYFrom={40} dataYTo={-40}>
          <GlowStick2 width={80} height={3}/>
          <P white={true} center={true} middle={true}>{t.blackDiscs.disc2}</P>
        </MaterialContainer>
        <MaterialContainer padding={38} minWidth={235} className="center motion" columns={4} circle={true} material="almostBlack" dataYFrom={40} dataYTo={-40}>
          <GlowStick3 width={3} height={80}/>
          <GlowStick4 width={3} height={80}/>
          <P white={true} center={true} middle={true}>{t.blackDiscs.disc3}</P>
        </MaterialContainer>
        <MaterialContainer padding={38} minWidth={235} className="center motion" columns={4} circle={true} material="almostBlack" dataYFrom={40} dataYTo={-40}>
          <GlowStick1 width={80} height={3}/>
          <P white={true} center={true} middle={true}>{t.blackDiscs.disc4}</P>
        </MaterialContainer>
      </BlackDiscs>

      <Buttons className="center" mb={80} mt={40}>
        <Button title={t.blackDiscs.button1Title} linkTo={site.pages.invoice.defaultPath} />
        <Button title={t.blackDiscs.button2Title} linkTo={site.pages.slice.defaultPath} />
      </Buttons>

      <NarrowContent>
        <PaddedMaterialContainer zIndex={1} material="walled">
          <Wall id={"wall8"} hideMobile={true} height={40} position="bottom: 70px; left: -50px;"/>
          <Wall id={"wall9"} hideMobile={true} height={40} position="bottom: 0px; left: -50px;"/>
          <Wall id={"wall10"} hideMobile={true} height={40} position="bottom: -40px; right: -70px;"/>
          <Cross hideMobile={true} width={100} position="right: -115px; top: -100px;"/>
          <H3 color="#FF5F2D" strong={true}>{t.glassDiscs.walledTitle}</H3>
        </PaddedMaterialContainer>

        <div className="center">
          <BigGlassDiscs>
            <Wall id={"wall11"} hideMobile={true} height={64} longShadow={true} position="bottom: 120px; left: -200px;"/>
            <MaterialContainer width={320} minWidth={320} className="center motion" padding={60} columns={2} circle={true} material="lighterBlue" dataYFrom={40} dataYTo={-40}>
              <P white={true} strong={true} center={true}>{t.glassDiscs.disc1}</P>
            </MaterialContainer>
            <MaterialContainer width={320} minWidth={320} className="center motion" padding={60} columns={2} circle={true} material="lighterBlue" dataYFrom={40} dataYTo={-40}>
              <P white={true} strong={true} center={true}>{t.glassDiscs.disc2}</P>
            </MaterialContainer>
          </BigGlassDiscs>
        </div>

        <div className="center">
          <H3 strong={true} center={true} mt={40}>{t.glassDiscs.bodyBottom}</H3>
        </div>
        <Buttons className="center" mt={40} mb={0}>
          <Button external={true} title={t.glassDiscs.buttonTitle} linkTo={merchantOnboardingURL}/>
        </Buttons>
      </NarrowContent>
    </Layout>
  );
}

export default AboutPage


const BlackDiscs = styled.div`
  position:relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin: 40px 0;
  margin-bottom: 30px;
  
  & > div {
    margin: 10px;
  }
  
  @media only screen and (min-width: 1024px) {
    margin: 40px 0;
  }
  
  @media only screen and (min-width: 1024px) {
    justify-content: space-between;
    
    & > div {
      margin: unset;
    }
  }
`

const PaddedMaterialContainer = styled(MaterialContainer)`
  padding: 60px 48px;
  border-radius: 7px;
  
  @media only screen and (max-width: 767px) {
    padding: 24px;
    padding-bottom: 56px;
  }
`

const BigGlassDiscs = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: -40px;
  display: flex;
  
  justify-content: center;
  align-items: center;
  flex-flow: column;
  
  & > div {
    margin-bottom: 20px;
    flex: 0 0 auto;
      
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  
  @media only screen and (min-width: 768px) {
    flex-flow: row wrap;
    margin-bottom: 40px;
    
    & > div {
      margin-bottom: 0;
      margin-right: 40px;
        
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`

const GlowStick1 = styled(GlowStick)`
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
`

const GlowStick2 = styled(GlowStick)`
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
`
const GlowStick3 = styled(GlowStick)`
  position: absolute;
  top: 0;
  right: 25%;
  transform: translate(-50%, -30%);
`
const GlowStick4 = styled(GlowStick)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`

const BubbleAnimator = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 6;
`

const BubbleContainer = styled(MaterialContainer)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -100%);
  
  @media only screen and (min-width: 768px) {
    position: absolute;
    top: -140px;
    left: 24px;
    transform: unset;
    
    & > div {
      padding: 24px;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    position: absolute;
    bottom: 110px;
    top: unset;
    left: 30px;
    transform: unset;
    border-radius: 50%;
    width: 300px;
    
    & > div {
      padding: 48px;
    }
  }
`

const GlassTextPanel = styled(MaterialContainer)`
  margin-top: -80px;
  margin-bottom: 90px;
  
  @media only screen and (max-width: 767px) {
    margin-top: -40px;
    margin-bottom: 40px;
    
    & > h3 {
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    margin-top: -140px;
  }
`

const TextUnderPageTitle = styled(H3)`
  @media only screen and (max-width: 767px) {
    margin-top: -16px;
  }
`
